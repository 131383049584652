/* import React, { useEffect, useState } from 'react';*/
/* import { useNavigate } from 'react-router-dom'; */
/* import { Dropdown } from 'react-bootstrap'; */
/* import ModalLogin from './ModalLogin';
import ModalRegister from './ModalRegister'; */

function Header() {
  /* const navigate = useNavigate(); */
/*   const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false); */

 /*  const userData = JSON.parse(localStorage.getItem('userData')); */

/*   const openRegisterModal = () => {
    setShowLogin(false);
    setShowRegister(true);
  }; */

/*   useEffect(() => {
    if (userData && window.location.pathname === '/') {
      navigate("/index");
    } else if (!userData) {
      navigate("/");
    }
  }, [navigate, userData]);

  const handleLogout = () => {
    localStorage.removeItem('userData');
    navigate("/");
  };
 */
  return (
    <header>
      <nav className="navbar navbar-expand-lg  fixed-top" id="mainNavbar">
        <div className="container-fluid">
          <div className="logosMenu">
            <a className="EnlacesMenu" href="https://urosario.edu.co/" target="_blank" rel="noreferrer noopener">
              <img src="https://urosario.edu.co/sites/default/files/2024-03/logo-urosario.png" className="logosHeader logo_ur" alt="Logo Universidad del Rosario" />
            </a>
            <a className="EnlacesMenu" href="/">
              <img src="https://urosario.edu.co/sites/default/files/2024-05/logo-biblioteca-humana-v3.png" className="logosHeader logo_bh" alt="Logo Biblioteca Humana" />
            </a>
          </div>

          {/* Este boton no tiene ningun comportamiento */}



          {/* <div className="collapse navbar-collapse" id="navbarBibliotecaHumana">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {userData ? (
                <>
                  <div className="user_dropdown">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {userData.nombreCompleto}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={handleLogout}>Cerrar sesión</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="user_button hidden-xs">
                    <button className="btn btn-primary me-2" onClick={handleLogout}>Cerrar sesión</button>
                  </div>
                </>
              ) : (
                <>
                  <button className="btn btn-primary me-2 btn_entrar" onClick={() => setShowLogin(true)}>Ingresar</button>
                  <button className="btn btn-primary me-2 btn_registro" onClick={() => setShowRegister(true)}>Registrarse</button>
                </>
              )}
            </ul>
          </div> */}
        </div>
       {/*  {!userData && <ModalLogin show={showLogin} onHide={() => setShowLogin(false)} />}
        {!userData && <ModalRegister show={showRegister} onHide={() => setShowRegister(false)} />} */}
      </nav>
      {/* <ModalLogin show={showLogin} onHide={() => setShowLogin(false)} onRegisterOpen={openRegisterModal} />
      <ModalRegister show={showRegister} onHide={() => setShowRegister(false)} /> */}
    </header>
  );
}

export default Header;
