import React from 'react';

const Licencia = () => {
  return (
    <div className="politica-wrapper">
      <div className="politica-document">
        <h1 class="text-center">Términos y Condiciones de Uso de la Biblioteca Humana</h1>
        <p>Bienvenido a LA BIBLIOTECA HUMANA DE LA UNIVERSIDAD DEL ROSARIO (en adelante "BIBLIOTECA HUMANA UR") un portal innovador diseñado para extender y complementar el conocimiento encontrado en fuentes tradicionales a través de las experiencias compartidas por los autores de cada uno de los capítulos disponibles en nuestra plataforma.</p>

        <h2>Servicios y Usuarios</h2>
        <p>Estos términos y condiciones aplican a todos los usuarios de los servicios incluyendo a los estudiantes, empleados, egresados y/o usuarios externos. El término servicios hace referencia a todos los contenidos y funcionalidades.</p>

        <h2>Aceptación de los Términos</h2>
        <p>Al acceder y utilizar la BIBLIOTECA HUMANA UR usted acepta estar sujeto a estos términos y condiciones (en adelante "Términos") así como a nuestra política de privacidad. Si no está de acuerdo con alguna parte de estos Términos, no debe utilizar la plataforma.</p>

        <h2>Acceso y Registro</h2>
        <p>El acceso a los contenidos de la BIBLIOTECA HUMANA UR está disponible para el público en general pero requiere un registro previo del usuario. Este registro es gratuito y tiene como objetivo crear una experiencia de usuario personalizada y segura.</p>

        <h2>Contenido y Derechos de Autor</h2>
        <p>Los contenidos disponibles en la BIBLIOTECA HUMANA UR incluidas las carátulas, títulos, descripciones y enlaces a los libros son proporcionados por los autores o tienen licencia para su uso por parte de LA UNIVERSIDAD DEL ROSARIO. Estos contenidos están protegidos por las leyes de derechos de autor y son propiedad de sus respectivos autores, licenciantes o directamente por LA UNIVERSIDAD DEL ROSARIO.</p>

        <h2>Uso del Contenido</h2>
        <p>El usuario se compromete a utilizar el contenido disponible en la plataforma exclusivamente para fines personales y no comerciales. Queda expresamente prohibida la copia, reproducción, modificación o distribución del contenido sin la autorización previa por escrito de la Universidad del Rosario o los titulares de los derechos de autor.</p>

        <h2>Exención de Responsabilidad</h2>
        <p>LA BIBLIOTECA HUMANA UR es un espacio para compartir conocimientos y experiencias. Las opiniones y expresiones contenidas en los materiales disponibles no necesariamente reflejan la posición de la Universidad del Rosario.</p>

        <h2>Conducta del Usuario</h2>
        <p>El usuario se compromete a utilizar la plataforma de manera responsable y respetuosa, evitando cualquier comportamiento que pueda ser considerado inapropiado, ofensivo o ilegal. LA UNIVERSIDAD DEL ROSARIO se reserva el derecho de suspender o terminar el acceso del usuario a la plataforma por violaciones a estos Términos en cualquier momento y sin previo aviso.</p>

        <h2>Modificaciones a los Términos</h2>
        <p>LA UNIVERSIDAD DEL ROSARIO se reserva el derecho de modificar estos Términos en cualquier momento. Es responsabilidad del usuario revisar periódicamente los Términos actualizados.</p>

        <h2>Responsabilidad</h2>
        <p>LA UNIVERSIDAD DEL ROSARIO no otorga garantía alguna sobre la exactitud, confiabilidad u oportunidad de la información, los contenidos, los servicios, los textos, el software, las gráficas, etc. de LA BIBLIOTECA HUMANA UR. LA UNIVERSIDAD DEL ROSARIO no será responsable por daños que los programas sobre los cuales corre la plataforma ni por los archivos que baje del mismo ocasionen en el equipo o los archivos de EL USUARIO, incluyendo virus. LA UNIVERSIDAD DEL ROSARIO no será responsable por los perjuicios que EL USUARIO pueda causar a terceros en la utilización de la plataforma. EL USUARIO asume todos los riesgos asociados con tratar con otros usuarios con los cuales entre en contacto a través de la plataforma. En caso de que EL USUARIO tenga alguna disputa con otros usuarios, este libera a LA UNIVERSIDAD DEL ROSARIO de cualquier reclamación, demanda o daño de cualquier naturaleza que llegue a ocurrir o que de cualquier otra forma se relacione con dicho conflicto.</p>

        <h2>Restricciones</h2>
        <p>EL USUARIO únicamente podrá ingresar a las secciones de la plataforma que le sean autorizadas por LA UNIVERSIDAD DEL ROSARIO, por tanto se abstendrá de utilizar cualquier medio para violar la seguridad y restricciones de la plataforma. LA UNIVERSIDAD DEL ROSARIO se reserva el derecho de suspender el acceso del usuario a la plataforma por violaciones a estos Términos en cualquier momento y sin previo aviso.</p>

        <h2>Ley Aplicable y Jurisdicción</h2>
        <p>Estos Términos se rigen e interpretan de acuerdo con las leyes de la República de Colombia. Cualquier disputa relacionada con estos Términos estará sujeta a la jurisdicción exclusiva de los tribunales de Colombia.</p>

        <h2>Notificaciones</h2>
        <p>EL USUARIO acepta que toda comunicación y/o modificación en relación con este acuerdo se efectuará a través del sitio web de LA UNIVERSIDAD DEL ROSARIO.</p>
      </div>
    </div>
  );
};

export default Licencia;
