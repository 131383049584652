import React, { useState, useEffect } from 'react';

const Footer = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const userHasAcceptedCookies = localStorage.getItem('userHasAcceptedCookies');
    if (!userHasAcceptedCookies) {
      setShowBanner(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('userHasAcceptedCookies', 'true');
    localStorage.setItem('lastSession', new Date().toISOString());
    setShowBanner(false);
  };

  const handleDeclineCookies = () => {
    setShowBanner(false);
  };




  return (
    <>

    {showBanner && (
        <div className="cookie" >
          <p>
            Este sitio utiliza cookies para mejorar tu experiencia de usuario. Si sigues navegando por el sitio, entendemos que aceptas estos términos. Ver política de cookies <a href="https://urosario.edu.co/static/Politica-tratamiento-cookies/" target="_blank" rel="noopener noreferrer">aquí</a>.
          </p>
          <div id="popup-buttons" className="eu-cookie-compliance-buttons">
              <button onClick={handleAcceptCookies}>Aceptar</button>
              <button onClick={handleDeclineCookies}>No, gracias</button>
          </div>
        </div>
      )}
      <footer className="text-justify">

        <div className="container">
          <div className="footer-head">
            <p>UN PROYECTO DE CRAI</p>
            <div className="footer-logo">
              <a className="EnlacesMenu" href="https://crai.urosario.edu.co/" target="_blank" rel="noreferrer noopener">
                <img src="https://urosario.edu.co/sites/default/files/2024-04/Logo_CRAI.png" alt="CRAI Logo" className="footer-logo" />
              </a>
            </div>
          </div>
          <div className="footer-contents d-flex text-justify">

            <div className="md-6 xs-12 lg-6" >
              <p className="disclaimer">Las ideas expresadas en cada libro de la Biblioteca Humana son de entera responsabilidad de los autores, y no reflejan las opiniones e ideología de la Universidad del Rosario, en caso de presentarse cualquier reclamación, demanda o acción por terceros frente al contenido de los capítulos los/as/es autores/as/es se obligan a asumir toda la responsabilidad legal, penal, civil, administrativa y/o de cualquier otra naturaleza que pueda surgir en relación con el mismo y a mantener indemne a la Universidad del Rosario.</p>
            </div>
            <div className="footer-block md-4 xs-12 lg-4 text-right">
              <p className="escribenos">¿Tienes alguna pregunta, o quieres hacer parte de un libro humano? Escríbenos: <strong>bibliotecahumana@urosario.edu.co</strong> </p>

            </div>
          </div>
          <div className="footer-bottom">
            <p>© 2024 Universidad del Rosario. Todos los derechos reservados</p>
            <div className="footer-links">
              <a href="/licencia" target="_blank" rel="noopener noreferrer">Términos y condiciones de uso</a> |
              <a href="/politica" target="_blank" rel="noopener noreferrer">Política de privacidad y protección de datos</a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
