// Politica.js
import React from 'react';

const Politica = () => {
  return (
    <div className="politica-wrapper">
      <div className="politica-document">
        <h1>POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS</h1>
        <p>Bienvenido a la Biblioteca Humana de la Universidad del Rosario. Valoramos la privacidad y la seguridad de los datos de nuestros usuarios. Este documento describe nuestras prácticas de privacidad y cómo tratamos la información personal que recopilamos de nuestros usuarios.</p>
        
        <h2>Recopilación de datos</h2>
        <p>Cuando te registras y utilizas la Biblioteca Humana UR, recopilamos información que nos proporcionas directamente. Esto puede incluir:</p>
        <ul>
          <li><strong>Datos de identificación personal:</strong> como tu nombre, apellido y correo electrónico. Estos datos son necesarios para crear tu cuenta y proporcionarte acceso a nuestros servicios.</li>
          <li><strong>Información de contacto:</strong> que utilizamos exclusivamente para comunicarnos contigo en relación con actualizaciones, noticias o información relevante sobre nuestra plataforma y los servicios que ofrecemos.</li>
        </ul>
        
        <h2>Uso de datos</h2>
        <p>La información que recopilamos se utiliza en los siguientes propósitos:</p>
        <ul>
          <li>Para identificarte como usuario de nuestra plataforma y permitirte acceder a diferentes recursos personalizados.</li>
          <li>Para ofrecerte una experiencia personalizada, adaptando nuestro contenido y servicios a tus intereses y preferencias.</li>
          <li>Para comunicarnos contigo, enviarte información sobre actualizaciones, cambios en nuestros servicios o nuevas ofertas que puedan interesarte, siempre en relación directa con los servicios de la Biblioteca Humana UR.</li>
        </ul>
        
        <h2>Seguridad de los datos</h2>
        <p>Nos comprometemos a proteger los datos personales de nuestros usuarios. Implementamos medidas técnicas y organizativas adecuadas para evitar el acceso no autorizado, la divulgación, alteración o destrucción de la información personal.</p>
        
        <h2>Compartir y divulgar información</h2>
        <p>No compartimos ni vendemos tus datos personales a terceros para fines comerciales o de marketing.</p>
        
        <h2>Derechos de los usuarios</h2>
        <p>Como usuario, tienes derecho a:</p>
        <ul>
          <li>Acceder, rectificar o solicitar la eliminación de tus datos personales cuando lo desees.</li>
          <li>Solicitar una limitación del tratamiento de tus datos personales.</li>
          <li>Oponerte al tratamiento de tus datos.</li>
          <li>Retirar tu consentimiento en cualquier momento.</li>
        </ul>
        
        <h2>Cambios en la Política de privacidad</h2>
        <p>Esta Política de Privacidad puede actualizarse periódicamente. Te notificaremos sobre cualquier cambio significativo y pondremos a tu disposición la versión actualizada en nuestra plataforma.</p>
        
        <h2>Contacto</h2>
        <p>Si tienes preguntas sobre esta Política de Privacidad o sobre cómo tratamos tus datos personales, por favor, contacta con nosotros a través de la información de contacto proporcionada en nuestra plataforma.</p>
      </div>
    </div>
  );
};

export default Politica;
