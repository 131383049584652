import React from 'react';
import { Routes, Route } from 'react-router-dom';
/* import HomePage from './components/HomePage'; */
import IndexPage from './components/IndexPage';
import Licencia from './components/Licencia';
import Politica from './components/Politica';
import Logout from './components/Logout';
import Libro from './components/Libro';
import AdminContent from './components/AdminContent'; // Importar el nuevo componente para admin

/* import ContactForm  from './components/ContactForm';
 */
function App() {
  return (
    <div>
      <Routes>
        {/* <Route path="/" element={<HomePage />} />  */}{/* Ruta de la página de inicio */}
        <Route path="/" element={<IndexPage />}/> 
        <Route path="/index" element={<IndexPage />} /> {/* Ruta después del login exitoso */}
        <Route path="/licencia" element={<Licencia />} />
        <Route path="/politica" element={<Politica />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/libro2/:id" element={<Libro />} />
        <Route path="/admin" element={<AdminContent />} /> {/* Nueva ruta para el contenido de administración */}
        {/* <Route path="/contact" element={<ContactForm />} /> */}
        {/* Puedes agregar más rutas según sea necesario */}
      </Routes>
    </div>
  );
}

export default App;
