import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import Slider from 'react-slick'; // Importa el componente Slider
/* import { slice } from 'string-utils'; */
import "slick-carousel/slick/slick.css"; // Estilos por defecto de slick
import "slick-carousel/slick/slick-theme.css"; // Tema por defecto de slick
import './Book.css'; // Asegúrate de tener tus estilos personalizados
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';



function IndexPage() {
  const [libros, setLibros] = useState([]);
  const [verLibro, setVerLibro] = useState(null); // Nuevo estado para controlar la visualización del libro en un iframe
  const [isMuted, setIsMuted] = useState(true); // Estado para controlar si el video está en silencio
  const [libroSeleccionado, setLibroSeleccionado] = useState({}); //visualización del libro seleccionado aleatoriamente

  /*  libroSeleccionado.idObjeto=7;
   libroSeleccionado.Titulo="Límites y encuentros.  Historias de las fronteras colombianas";
   libroSeleccionado.ruta='/imagenes/7/trailer.mp4';
   libroSeleccionado.url_libro='/libro/7'; */

  // Cargar libros desde la API al montar el componente
  useEffect(() => {
    const fetchLibros = async () => {
      try {
        const response = await axios.get('/api/libros');
        setLibros(response.data);
        const librosFiltrados = response.data.filter(libro => libro.status === '1');
        let posicion = Math.floor(Math.random() * librosFiltrados.length);
        const libroAleatorio = librosFiltrados[posicion];
        const libroConRuta = {
          ...libroAleatorio,
          /* ruta: `https://bibliotecahumana.urosario.edu.co/imagenes/${libroAleatorio.idObjeto}/trailer.mp4` */
          ruta: `https://bibliotecahumana.urosario.edu.co/imagenes/8/trailer.mp4`
        };
        setLibroSeleccionado(libroConRuta);

      } catch (error) {
        console.error('Error al obtener libros:', error);
      }
    };
    fetchLibros();
  }, []);

  /*   useEffect(() => {
      console.log(libroSeleccionado);
      console.log(libroSeleccionado.ruta);
    }, [libroSeleccionado]); */


  const settings = {
    autoplay: false,
    autoplaySpeed: 4000,
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    centerMode: true,
    centerPadding: '10px',
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: !0,
        dots: !0
      }
    }, {
      breakpoint: 769,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }, {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }, {
      breakpoint: 468,
      settings: {
        autoplay: !0,
        autoplaySpeed: 8e3,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }],
  };

  let userData = JSON.parse(localStorage.getItem('userData'));

  const toggleMute = () => {
    const video = document.getElementById('background-video');
    video.muted = !video.muted;
    setIsMuted(video.muted);
  };

  useEffect(() => {
    if (libroSeleccionado && libroSeleccionado.Titulo) {
      // Aquí podrías manejar la lógica si necesitas hacer algo más una vez que sabes que el título está disponible
      console.log("Título disponible:", libroSeleccionado.Titulo);
    }
  }, [libroSeleccionado]);


/*   const renderTitulo = (libro) => {
    // Asegura que el libro y su título estén definidos antes de proceder
    if (!libro || !libro.Titulo) {
      return <h1 className="tituloLibro">Cargando título...</h1>; // Mostrar mensaje de carga o similar
    }

    const partesTitulo = libro.Titulo.split('.');
    if (partesTitulo.length > 1) {
      return (
        <>
          <h1 className="tituloLibro">{partesTitulo[0].trim()}<span>.</span></h1>
          <p className="text">{partesTitulo[1].trim()}</p>
        </>
      );
    } else {
      return <h1 className="tituloLibro">{libro.Titulo}</h1>;
    }
  }; */

  return (
    <div>
      <Header />
      <div className="banner">
        <video autoPlay loop muted playsInline id="background-video">
          <source src="/imagenes/25/trailer.mp4" type="video/mp4" />
        </video>


        {/* {libroSeleccionado ? (
          <video autoPlay loop muted playsInline id="background-video">
            <source src={libroSeleccionado ? `${libroSeleccionado.ruta}` : ''} type="video/mp4" />
          </video>
        ) : (
          // Opcional: Mostrar un video por defecto o un mensaje si no hay libro seleccionado
          <video autoPlay loop muted playsInline id="background-video">
            <source src="/imagenes/81/trailer.mp4" type="video/mp4" />
          </video>
        )} */}

        <div className="banner-message">
          {/* {libroSeleccionado ? renderTitulo(libroSeleccionado) : ''} */}
          <h1 className="tituloLibro">La voz de la diferencia<span>.</span></h1>
          <p className="text">Discapacidad intelectual</p>
          <p className="ver_ahora text mb-md-5">
            {/* <a href={libroSeleccionado ? `/libro/${libroSeleccionado.idObjeto}` : '#'} className="btn btn_ver_ahora">Ver ahora</a> */}
            <a href='/libro/25' className="btn btn_ver_ahora">Ver ahora</a>
          </p>
        </div>

        <div style={{ position: 'absolute', bottom: 0, right: 0, padding: '10px' }}>
          <button onClick={toggleMute} style={{ background: 'none', border: 'none', cursor: 'pointer' }} title={isMuted ? "activar sonido" : "desactivar sonido"}>
            {isMuted ?
              <FontAwesomeIcon icon={faVolumeMute} style={{ color: "#FFF" }} className="fa-sm" /> :
              <FontAwesomeIcon icon={faVolumeUp} style={{ color: "#FFF" }} className="fa-sm" />
            }
          </button>
        </div>
      </div>

      {verLibro ? (
        <div>
          <button onClick={() => setVerLibro(null)}>Volver al listado de libros</button>
          <iframe
            src={`${verLibro}`}
            title="Vista del Libro"
            width="100%"
            height="600px"
            style={{ border: "none" }}
          ></iframe>
        </div>
      ) : (
        <>
          <div className="container">
            <h3 className="Subtituloseccion">EN CARTELERA</h3>
            <Slider {...settings}>
              {libros.map((libro) => (
                <div key={libro.idObjeto} className="book-container m-2">
                  {parseInt(libro.status) === 1 ? (
                    <a href={libro.url_libro} className="book-link" onClick={() => setVerLibro(libro.url_libro)}>
                      <img src={libro.url_caratula} alt={libro.Titulo} className="book-image" />
                      <div className="book-description">
                        <p className="texto-justify">{libro.descripcion.slice(0, 500)}</p>
                      </div>
                    </a>
                  ) : (
                    <a href={libro.url_libro} className="book-link disabled" onClick={(e) => e.preventDefault()} disabled readOnly >
                      <img src={libro.url_caratula} alt={libro.Titulo} className="book-image" />
                      <div className="book-description">
                        <p className="texto-justify">{libro.descripcion.slice(0, 500)}</p>
                      </div>
                    </a>

                  )}

                </div>
              ))}
            </Slider>
          </div>
        </>
      )
      }
      <Footer />
    </div >
  );
}

export default IndexPage;
