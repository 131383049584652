import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
   /*  sessionStorage.removeItem('userData'); */
   localStorage.removeItem('userData');
    navigate('/');
  }, [navigate]); // Asegúrate de incluir navigate en la lista de dependencias.

  // Opcionalmente, puedes renderizar algo mientras se está procesando el cierre de sesión.
  return (
    <div>Cerrando sesión...</div>
  );
}

export default Logout;
