import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Tab, Nav, Col } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';
import './Book.css';

function Libro() {
    const [book, setBook] = useState(null);
    const [chapters, setChapters] = useState([]);
    /* const [isUserLoggedIn, setIsUserLoggedIn] = useState(false); */
    /* const [persona, setPersona] = useState(null); */
    const [error, setError] = useState(null);
    const server_base = 'https://bibliotecahumana.urosario.edu.co';
    const { id } = useParams();

    useEffect(() => {

        if (!id) {
            setError('No se ha proporcionado información suficiente para mostrar el libro.');
            return;
        }

        const fetchBook = async () => {
            try {
                const response = await axios.get(`${server_base}/api/libro/${id}`);
                setBook(response.data[0]);
                const chaptersResponse = await axios.get(`${server_base}/api/libro/${id}/capitulos`);
                setChapters(chaptersResponse.data);
            } catch (error) {
                console.error(error);
                setError('Error al obtener información del libro');
            }
        };
        fetchBook();
    }, [id]);

    useEffect(() => {
        if (book) {
            document.title = book.title;
            const metaDescription = document.querySelector('meta[name="description"]');
            if (metaDescription) {
                metaDescription.setAttribute("content", book.descripcion);
            }
        }
    }, [book]);



    if (!book) {
        return (
            <div>
                <Header />
                <p className="errorMsg">Cargando...</p>
                <Footer />
            </div>
        );
    }


    if (error) {
        return (
            <div>
                <Header />
                <p className="errorMsg">{error}</p>
                <Footer />
            </div>
        );
    }

    const renderAcknowledgements = () => {
        const parts = book.agradecimientos.split("/");
        return parts.map(part => <p key={part}>{part}</p>);
    };

    const renderCredits = () => {
        return book.creditos.split("*").map((section, index) => {
            const content = section.split("$");
            return (
                <div key={index}>
                    {content.map((item, idx) => (
                        <p key={idx} className={idx === 0 ? "seccionT" : "seccion"}>{item}</p>
                    ))}
                </div>
            );
        });
    };

    return (
        <div className="libro">
            <Header />
            <div className="alturaPorDefecto">
                <div className="miga-pan">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/index">Inicio</a></li>
                            <li className="breadcrumb-item">{book.title}</li>
                        </ol>
                    </nav>
                </div>
                <div className='row mt-5'>
                    <div className="col-sm-3">
                        <div className="text-center">
                            <img src={book.url_cover} alt="Portada" className="imagePortada" />
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="container">
                            <h5><b className='title-segmento'>Tabla de contenido</b></h5>
                            <div className="container1 mt-3">
                                <div className="list-group tablaCont">
                                    {chapters.map((chapter, index) => (
                                        <a href={`${server_base}/${id}/capitulo/${chapter.idObjeto}/view`} className="capitulo list-group-item list-group-item-action">
                                            <b>{chapter.title}</b>
                                            <span className='ajuste-contenido'><b>{chapter.duracion}</b></span>
                                            <br />
                                            <span className="bh_author">{chapter.autor}</span>
                                        </a>
                                    ))}
                                    <a href={`${server_base}/${id}/bibliografia`} className="capitulo list-group-item list-group-item-action"><b>Bibliografía complementaria</b></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-5">
                        <div className="list-group">
                            <div className="embed-responsive embed-responsive-16by9 mt-3">
                                <iframe src={book.trailer} width="100%" height="300" frameBorder="0" scrolling="no" allowFullScreen="" title="Trailer Libro"></iframe>
                            </div>
                        </div>
                    </div>
                    <div>
                        {/* agradecimientos */}

                        <Col sm={5}>
                            <div className="container mt-3 creditos_agradecimientos">
                                <Tab.Container defaultActiveKey="agradecimientos">
                                    <Nav variant="pills" className="pb-2">
                                        <Nav.Item>
                                            <Nav.Link eventKey="agradecimientos">Agradecimientos</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="creditos">Créditos</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="agradecimientos">
                                            {renderAcknowledgements()}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="creditos">
                                            {renderCredits()}
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </Col>




                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Libro;
