import React, { useEffect, useState } from 'react';

function AdminContent() {
  const [content, setContent] = useState(''); // Estado para almacenar el contenido de index.php

  useEffect(() => {
    // Realizar una solicitud fetch al archivo PHP
    fetch('../../../../admin/index.php') 
      .then(response => response.text())
      .then(data => {
        setContent(data); // Actualizar el estado con el contenido de index.php
      })
      .catch(error => {
        console.error('Error al cargar el contenido de administración:', error);
        // Manejar el error de alguna manera (mostrar un mensaje, etc.)
      });
  }, []); // El array vacío asegura que el useEffect se ejecute solo una vez al montar el componente

  return (
    <div dangerouslySetInnerHTML={{ __html: content }} /> // Renderizar el contenido de forma segura
  );
}

export default AdminContent;
